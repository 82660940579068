import React from 'react'
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'

class AuthorizeRoute extends React.Component {

    render() {
        const authenticated = this.props.isAuthenticated;        
        const { component: Component, ...rest } = this.props;

        //This is the one time that we can pass an anonymous function into props without causing too many re-renders. As the 
        //Route component's render method simply returns the component generated in the anonymous method, the type of that
        //component will match between re-renders. 
        //See: https://medium.com/@ilovezcd/difference-between-render-and-component-prop-on-react-router-v4-368ca7fedbec
        return <Route {...rest}
            render={(props) => {                          
                const redirectUrl = `/login?ReturnUrl=${props.location.pathname}`; //encodeURI(window.location.href)}`

                if (authenticated) {
                    return <Component {...props} />
                } else {                    
                    return <Redirect to={redirectUrl} />
                }
            }} />
    }
}

export default connect(state => state.login, null)(AuthorizeRoute);