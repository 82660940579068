import * as React from 'react';
import { connect } from 'react-redux';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as LoginStore from '../store/AuthenticationStore';
import './NavMenu.css';

class NavMenu extends React.PureComponent {
    state = {
        isOpen: false
    };

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">Welcome</NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem>                                
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/market-data">Market data</NavLink>
                                </NavItem>    
                                <NavItem>
                                    {this.props.isAuthenticated ? <NavLink tag={Link} className="text-dark" to="#" onClick={() => this.props.logout() }>Logout</NavLink>
                                        : <NavLink tag={Link} className="text-dark" to="/login">Login</NavLink>}
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
    //<NavItem><NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink></NavItem >

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default connect(
    state => state.login,
    Object.assign({}, LoginStore.actionCreators)
)(NavMenu);