import { call } from 'redux-saga/effects';
import { takeLatestRoutine } from '../framework/sagaRoutines'
import { actionCreators } from '../store/MarketDataStore';
import { sendGetRequest } from './api';

export function* marketDataSaga() {
    console.log("MarketDataSaga started");    
    yield takeLatestRoutine(actionCreators.getMarketData, getMarketData);
}

//Redux-saga-routines:
function* getMarketData(action) {
    const marketId = action.payload;
    return yield call(sendGetRequest, `api/MarketData/${marketId}`);
}
