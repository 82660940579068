import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { actionCreators as authenticationActionCreators } from '../store/AuthenticationStore';

export const invokeHandlerForRoutine = (
    routine, //RoutineActionCreator
    handler //Function to load the actual data from api    
) =>
    function* (action) {
        let data = undefined;
        let error = undefined;
        try {
            yield put(routine.request());
            const response = yield* handler(action);
            console.log("Framework saga response from handler:", response);

            if (response.status === 200) {
                data = yield response.json();
            } else if (response.status === 204) {
                error = "Not found";
            } else if (response.status === 401) {
                //if 401 response returned from api, logout from application & redirect to login page.
                yield put(authenticationActionCreators.logout());
                return;
            } else if (response.status >= 400 && response.status < 500) {
                error = yield response.json(); //Exception if the response is not json
            } else if (response.status >= 500) {
                error = "The server returned an error";
            } else {
                error = "Unexpected code path in Saga!";
            }
        } catch (ex) {
            console.log("Network error during fetch or exception decoding json: ", ex);
            error = "Error communicating with the server";
        }

        //We could do this in the 200 status handler above, but then the exception catch block will also pick up
        //errors generated by the React component rendering it!
        if (error) {
            yield put(routine.failure(error));
        } else {
            yield put(routine.success({ data, ...action.payload }));
        }
    }

export function takeLatestRoutine(routine, handler) {
    return takeLatest(routine.TRIGGER, invokeHandlerForRoutine(routine, handler))
}

export function takeEveryRoutine(routine, handler) {
    return takeEvery(routine.TRIGGER, invokeHandlerForRoutine(routine, handler))
}
