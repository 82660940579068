import * as Counter from './Counter';
import { reducer as reduxFormReducer } from 'redux-form'
import * as AuthenticationStore from './AuthenticationStore';
import * as MarketDataStore from './MarketDataStore';
import * as MarketListStore from './MarketListStore';
import * as MarketAnalysisStore from './MarketAnalysisStore';

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    form: reduxFormReducer,
    login: AuthenticationStore.reducer,
    marketData: MarketDataStore.reducer,
    marketList: MarketListStore.reducer,
    marketAnalysis: MarketAnalysisStore.reducer
};