import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Loading } from './utils/Loading'
import * as MarketListStore from '../store/MarketListStore';
import * as MarketDataStore from '../store/MarketDataStore';
import * as MarketAnalysisStore from '../store/MarketAnalysisStore';
import { MarketList } from './MarketList';
import { MarketAnalysisModal } from './MarketAnalysisModal';
import { MarketDataTable } from './MarketDataTable';

class Market extends React.PureComponent {

    // This method is called when the component is first added to the document
    componentDidMount() {
        this.ensureDataFetched(this.props.match.params.marketId);
    }

    //Note: we have to use fat arrow syntax here as it preserves "this"
    onChangeMarketDropdown = (event) => {
        const marketId = (event.target).value;
        this.props.push(`/market-data/${marketId}`);
        this.ensureDataFetched(marketId);
    }

    ensureDataFetched(marketIdAsString) {
        this.props.getMarketList();
        const marketId = parseInt(marketIdAsString, 10) || 0;
        this.setState({ marketId: marketId, modalIsOpen: false });
        if (marketId !== 0) {
            this.props.getMarketData(marketId); //Allow this to come from either the drop down or the URL.
        }
    }

    showMarketAnalysis = (reversalId) => {
        this.props.getMarketAnalysis(reversalId);
        this.setState(prevState => ({ ...prevState, modalIsOpen: true }));
    }   

    hideMarketAnalysis = () => {
        this.setState(prevState => ({ ...prevState, modalIsOpen: false }));
    }  

    render() {
        
        const marketList = <Loading component={MarketList}
                loading={this.props.marketList.loading}
                dataError={this.props.marketList.dataError}
                marketId={this.state ? this.state.marketId : "0"}
                onChangeMarketDropdown={this.onChangeMarketDropdown}
                marketList={this.props.marketList.data} />

        let mainContent = undefined;
        if (this.state && this.state.marketId) {
            if (this.props.marketData.dataError) {
                mainContent = <strong className="invalid-feedback" style={{ display: "block", marginBottom: "1rem", fontSize: "1rem" }}>{this.props.marketData.dataError}</strong>
            } else {
                mainContent = <MarketDataTable loading={this.props.marketData.loading} marketData={this.props.marketData.data} showDetails={this.showMarketAnalysis} />
            }
        }

        return (
            <React.Fragment>
                <h1>Market Data</h1>
                <p>Please choose a market from the drop down list:</p>
                {marketList}
                <br/>
                {mainContent}
                <MarketAnalysisModal isOpen={this.state && this.state.modalIsOpen} toggle={this.hideMarketAnalysis} marketAnalysis={this.props.marketAnalysis} />
            </React.Fragment>
            );
    }
}

export default connect(
    state => {
        return { marketData: state.marketData, marketList: state.marketList, marketAnalysis: state.marketAnalysis }
    }, // Selects which state properties are merged into the component's props
    { ...MarketDataStore.actionCreators, ...MarketListStore.actionCreators, ...MarketAnalysisStore.actionCreators, push } // Selects which action creators are merged into the component's props
)(Market);
