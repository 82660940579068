import * as React from 'react';

export const Loading = ({ component: Component, loading, dataError, ...rest }) => {
    if (loading) {
        console.log("Loading Component shouldn't re-render if loading=true and other props change", Component);
        return (
            <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        );
    } else if (dataError) {
        return <strong className="invalid-feedback" style={{ display: "block", marginBottom: "1rem", fontSize: "1rem" }}>{dataError}</strong>
    }
    else {
        return <Component {...rest} />
    }
}

