import { call } from 'redux-saga/effects';
import { takeLatestRoutine } from '../framework/sagaRoutines'
import { actionCreators } from '../store/MarketAnalysisStore';
import { sendGetRequest } from './api';

export function* marketAnalysisSaga() {
    console.log("MarketAnalysisSaga started");    
    yield takeLatestRoutine(actionCreators.getMarketAnalysis, getMarketAnalysis);
}

//Redux-saga-routines:
function* getMarketAnalysis(action) {
    const reversalId = action.payload;
    return yield call(sendGetRequest, `api/MarketData/GetMarketAnalysis/${reversalId}`);
}
