import * as React from 'react';
import './MarketDataTable.css';

export const MarketDataTable = (props) => {
    let tableBody = undefined;
    if (props.loading) {
        tableBody = (<tr><td colSpan="14">Loading...</td></tr>);
    } else {
        tableBody = renderTableBody(props.marketData, props.showDetails);
    }

    return (
        <table className='table table-striped'>
            <thead>
                <tr>
                    <td>Date</td>
                    <td>Wk Bull</td>
                    <td>Wk Bear</td>
                    <td>Mnth Bull</td>
                    <td>Mnth Bear</td>
                    <td>Year Bull</td>
                    <td>Year Bear</td>
                    <td>Breakout</td>
                    <td>Crash</td>
                    <td>Open</td>
                    <td>Close</td>
                    <td>High</td>
                    <td>Low</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {tableBody}
            </tbody>
        </table>
    );
}

const renderTableBody = (marketData, showDetails) => {
    if (marketData) {
        const scale = Math.pow(10, marketData.priceDecimalDigits);
        const reversalsAndPrices = marketData.reversalsAndPrices;

        return reversalsAndPrices.map((reversalAndPrice, i) => {
            //Pass the next one so we can do the colour coding
            let next = i < reversalsAndPrices.length - 1 ? reversalsAndPrices[i + 1].reversalInfo : null;
            return (<tr key={"marketData" + i}>{describeReversalAndPriceHtml(reversalAndPrice, next, scale, showDetails)}</tr>)
        })
    }
}

const valOrNA = (value, nextValue) => {
    if (value) {
        if (nextValue && value !== nextValue) {
            return <span style={{ color: 'blue' }}>{value}</span>
        }
        return value;
    }

    return "NA";
}

const formatPrice = (value, reversal, scale) => {
    const price = (value * scale).toFixed(0); //remove floating point randomness in javascript.

    if (price < reversal.weeklyBearish) {
        return <span style={{ color: '#DD0000' }}>{price}</span>
    }
    if (price < reversal.monthlyBearish) {
        return <span style={{ color: '#770000' }}>{price}</span>
    }
    if (price > reversal.weeklyBullish) {
        return <span style={{ color: '#00DD00' }}>{price}</span>
    }
    if (price > reversal.monthlyBullish) {
        return <span style={{ color: '#007700' }}>{price}</span>
    }

    return price;
}

const describeReversalAndPriceHtml = (reversalAndPrice, next, scale, showDetails) => {
    const reversal = reversalAndPrice.reversalInfo;
    const price = reversalAndPrice.priceInfo;

    return <React.Fragment>
        <td>{new Date(reversal.analysisDate).toLocaleDateString('en-GB')}</td>
        <td>{valOrNA(reversal.weeklyBullish, next ? next.weeklyBullish : null)}</td>
        <td>{valOrNA(reversal.weeklyBearish, next ? next.weeklyBearish : null)}</td>
        <td>{valOrNA(reversal.monthlyBullish, next ? next.monthlyBullish : null)}</td>
        <td>{valOrNA(reversal.monthlyBearish, next ? next.monthlyBearish : null)}</td>
        <td>{valOrNA(reversal.yearlyBullish, next ? next.yearlyBullish : null)}</td>
        <td>{valOrNA(reversal.yearlyBearish, next ? next.yearlyBearish : null)}</td>
        <td>{reversal.breakoutResistance ? reversal.breakoutResistance : "NA"}</td>
        <td>{reversal.crashIndicator ? reversal.crashIndicator : "NA"}</td>
        <td className="PriceVerticalLine">{formatPrice(price.open, reversal, scale)}</td>
        <td>{formatPrice(price.close, reversal, scale)}</td>
        <td>{formatPrice(price.high, reversal, scale)}</td>
        <td>{formatPrice(price.low, reversal, scale)}</td>
        <td><button type="button" className="btn btn-link" onClick={() => showDetails(reversal.id)}>info</button></td>
    </React.Fragment>
}


//export default connect(
//    null, // Selects which state properties are merged into the component's props
//    null // Selects which action creators are merged into the component's props
//)(MarketDataTable);
