import * as React from 'react';
import { connect } from 'react-redux';

const Home = () => (
    <div>
        <h1>Welcome</h1>
        <p>Welcome to this web site.</p>
    </div>
);

export default connect()(Home);
