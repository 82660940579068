import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Field, reduxForm, SubmissionError, startSubmit } from 'redux-form';
import './LoginForm.css';
import { actionCreators } from '../store/AuthenticationStore';

// Would just use "Component="Input" " in the Field, but having a renderField gives a specific place for validation errors, etc, to live.
const renderField = ({
    id,
    input,
    label,
    placeholder,
    type,
    required,
    meta: { touched, error, warning, pristine }
}) => {
    return <FormGroup>
        <Label for={id}>{label}</Label>
        <Input {...input} id={id} placeholder={placeholder} type={type} required={required} />
        {touched && error && <span className="invalid-feedback" style={{ display: "block", fontSize: "1rem" }}>{error}</span>}
    </FormGroup>
};

class LoginForm extends React.PureComponent {

    submit({ email, password }, dispatch) {
        let error = {};
        if (!email || email.trim() === '') {
            error.email = "Required";
        }
        if (!password || password.trim() === '') {
            error.password = "Required";
        }
        if (Object.keys(error).length > 0) {
            throw new SubmissionError(error);
        }

        let returnUrl = undefined;
        if (this.props.location.search.startsWith("?ReturnUrl=")) {
            returnUrl = this.props.location.search.substr(11);
        }

        //If we retured a promise from this method then submitting would be automatically
        //set to true, but because we don't we must set it ourselves.
        dispatch(startSubmit('loginFormIdent'));
        dispatch(actionCreators.login({ email, password, returnUrl }));
    }

    render() {        
        const { error, handleSubmit, submitting } = this.props; //error prop gets added by redux forms if we call stopSubmit{_error="..."}
        if (this.props.isAuthenticated) {
            return (
                <Container className="App">
                    <h2 className="form-title">You are alrady signed in</h2>
                </Container>
            );
        }

        return (
            <Container className="App">
                <h2 className="form-title">Sign In</h2>
                <Form onSubmit={handleSubmit(this.submit.bind(this))}>

                    <Field
                        id="email"
                        label="Email"
                        name="email"
                        type="email"
                        component={renderField}
                        placeholder="myemail@email.com"
                        required="required"
                        className="form-control"
                    />
                    <Field
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        component={renderField}
                        placeholder="****"
                        required="required"
                        className="form-control"
                    />
                    {error && !submitting && <strong className="invalid-feedback" style={{ display: "block", marginBottom: "1rem", fontSize: "1rem" }}>{error}</strong>}
                    <div>
                        <Button color="primary" disabled={submitting}>
                            {submitting &&
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: "0.375rem" }} ></span>
                            }
                            Submit
                        </Button>
                    </div>
                </Form>
            </Container>
        );
    }
}

LoginForm = connect(
    state => state.login,
)(LoginForm);

export default reduxForm({
    form: 'loginFormIdent' // a unique identifier for this form
})(LoginForm);
