export function sendGetRequest(url) {
    // Default options are marked with *
    return fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: "same-origin", // include, *same-origin, omit
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + window.sessionStorage.getItem('JWT'),
        }
    });
}

//Used by Login form, hence no need to pass authetication token from session storage
export const postDataAnonymous = (url, data) =>
    fetch(url, {
        method: "POST",        
        cache: "no-cache",        
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });