import { appStartIsAuthenticatedSaga, loginSaga, logoutSaga } from './authenticationSaga';
import { marketDataSaga } from './marketDataSaga';
import { marketListSaga } from './marketListSaga';
import { marketAnalysisSaga } from './marketAnalysisSaga';
             
export const initAllSagas = (sagaMiddleware) => {
    const sagas = [
        appStartIsAuthenticatedSaga.bind(this),
        loginSaga.bind(this),
        logoutSaga.bind(this),
        marketDataSaga.bind(this),
        marketListSaga.bind(this),
        marketAnalysisSaga.bind(this),
    ];
    sagas.forEach(saga => sagaMiddleware.run(saga))
};