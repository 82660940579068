import { handleActions } from 'redux-actions'

export const createRoutineReducerActions = (baseAction) => ({
    [baseAction.REQUEST]: state => ({
        ...state,
        loading: true
    }),
    [baseAction.SUCCESS]: (state, action) => ({
        ...state,
        loading: false,
        ...action.payload
    }),
    [baseAction.FAILURE]: (state, action) =>
        ({
            ...state,
            loading: false,
            dataError: action.payload
        })
});

export const createRoutineReducer = (
    baseAction, //The createRoutine result
    initialData = undefined, //optional initial state before we call trigger on the routine
) => handleActions(createRoutineReducerActions(baseAction),
    {
        loading: initialData === undefined ? true : false,
        data: initialData,
    }
    );