import * as React from 'react';

export const MarketList = (props) => {
    return (
        <div>
            <select
                value={props.marketId}
                onChange={props.onChangeMarketDropdown}>
                <option key="0" value="0">Please select a market</option>
                {props.marketList.map((market, i) => {
                    return (<option key={i} value={market.id}>{market.name}</option>);
                })}
            </select>
        </div>
    );
}

