import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import AuthorizeRoute from './components/authorize/AuthorizeRoute'
import Home from './components/Home';
import Counter from './components/Counter';
import LoginForm from './components/LoginForm';
import Market from './components/Market';

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/login' component={LoginForm} />
        <AuthorizeRoute path='/market-data/:marketId?' component={Market} />
    </Layout>
);
