import { call } from 'redux-saga/effects';
import { takeLatestRoutine } from '../framework/sagaRoutines'
import { actionCreators } from '../store/MarketListStore';
import { sendGetRequest } from './api';

export function* marketListSaga() {
    console.log("MarketListSaga started");
    //yield takeLatest(MARKET_LIST, getMarketList);
    yield takeLatestRoutine(actionCreators.getMarketList, getMarketList);
}

//Redux-saga-routines:
function* getMarketList() {
    return yield call(sendGetRequest, `api/Market/GetMarkets`);
}
