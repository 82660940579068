import { createRoutine } from 'redux-saga-routines'
import { createAction, handleActions } from 'redux-actions'
import { createRoutineReducerActions } from '../framework/routineReducer'

//export const LOGIN = 'LOGIN';
//export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
//export const LOGOUT = 'LOGOUT';

//export const actionCreators = {
//    login: (email, password, redirectUrl) => ({
//        type: LOGIN,
//        email,
//        password,
//        redirectUrl
//    }),
//    loginSuccess: () => ({
//        type: LOGIN_SUCCESS        
//    }),
//    logout: () => ({
//        type: LOGOUT
//    })
//};

//export const reducer = (state, action) => {
//    if (state === undefined) {
//        return ({ isAuthenticated: false });
//    }

//    switch (action.type) {
//        case LOGIN:
//            return { loading: true };
//        case LOGIN_SUCCESS:
//            return { isAuthenticated: true };
//        case LOGOUT:
//            return { isAuthenticated: false };
//        default:
//            return state;
//    }
//};

export const actionCreators = {
    login: createRoutine('LOGIN'),
    authenticated: createAction("IS_AUTHENTICATED"),
    logout: createAction('LOGIN')
};

export const reducer = handleActions({
    ...createRoutineReducerActions(actionCreators.login),  //We're adding custom action reducers in addition to the standard routine reducers (tigger, success, failure, etc)
    [actionCreators.authenticated]: (state, action) => ({
        ...state,
        isAuthenticated: action.payload
    }),
    [actionCreators.logout]: state => ({
        ...state,
        isAuthenticated: false
    })
},
    {
        loading: true
    }
);
//export const reducer = handleActions(
//    {
//        [actionCreators.login.REQUEST]: state => ({
//            ...state,
//            loading: true
//        }),
//        [actionCreators.login.SUCCESS]: (state, action) => {
//            console.log("Login success store. Too late for the push from the saga?", action);
//            return ({
//                ...state,
//                loading: false,
//                data: action.payload,
//                isAuthenticated: true
//            })
//        },
//        [actionCreators.login.FAILURE]: (state, action) => ({
//            ...state,
//            loading: false,
//            dataError: action.payload
//        }),
//        [actionCreators.logout]: state => ({
//            ...state,            
//            isAuthenticated: false
//        })
//    },
//    {
//        loading: true
//    }
//);