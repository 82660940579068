import { createRoutine } from 'redux-saga-routines'
import { createRoutineReducer } from '../framework/routineReducer'

//export const MARKET_DATA = 'MARKET_DATA';
//export const MARKET_DATA_SUCCESS = 'MARKET_DATA_SUCCESS';
//export const MARKET_DATA_FAILURE = 'MARKET_DATA_FAILURE';

//export const actionCreators = {
//    getMarketData: (marketId) => ({
//        type: MARKET_DATA, marketId
//    }),
//    marketDataSuccess: (marketData) => ({
//        type: MARKET_DATA_SUCCESS, marketData
//    }),
//    marketDataFailure: (errorMessage) => ({
//        type: MARKET_DATA_FAILURE, errorMessage
//    }),
//};


export const actionCreators = {
    getMarketData: createRoutine('MARKET_DATA')
};

export const reducer = createRoutineReducer(actionCreators.getMarketData);


//export const reducer = (state, incomingAction) => {
//    if (state === undefined) {
//        return ({ loading: false });
//    }

//    const action = incomingAction;
//    switch (action.type) {
//        case MARKET_DATA:
//            return { marketData: state.marketData, loading: true };
//        case MARKET_DATA_SUCCESS:
//            return { marketData: incomingAction.marketData, loading: false };
//        case MARKET_DATA_FAILURE:
//            return { errorMessage: incomingAction.errorMessage, loading: false };
//        default:
//            return state;
//    }
//};
