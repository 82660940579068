import { takeLatest, put, call } from 'redux-saga/effects';
import { takeLatestRoutine } from '../framework/sagaRoutines'
import { actionCreators } from '../store/AuthenticationStore';
import { stopSubmit } from 'redux-form';
import { push } from 'connected-react-router';
import { postDataAnonymous } from './api';

const hasAlreadyExpired = (expiration) => expiration.valueOf() - Date.now() <= 0

// On a page reload we check if we've got a valid JWT token. If so then fire the LoginSuccess message so we know we're authenticated
export function* appStartIsAuthenticatedSaga() {
    console.log("appStartIsAuthenticatedSaga started");
    const authToken = window.sessionStorage.getItem('JWT');  //This would be an issue for server-side pre-rendering? May have to move to componentDidMount
    const authTokenExpiration = window.sessionStorage.getItem('JWTExpiration');

    // expired auth token
    if (authToken != null && authTokenExpiration != null && !hasAlreadyExpired(hasAlreadyExpired)) {
        //Sets isAuthenticated to true in the reducer. No need to call loginSuccess (especially as loginSaga isn't started yet!)
        yield put(actionCreators.authenticated(true)); 

        //TODO - set a timer to renew the authentication token 
        //timer = window.setTimeout(authManager.renewAccessToken, delay);
        return;
    } else if (authToken) {
        //Auth token has expired so forget it
        window.sessionStorage.removeItem('JWT');
        window.sessionStorage.removeItem('JWTExpiation');        
    }
}

export function* loginSaga() {
    console.log("loginSaga started");
    yield takeLatestRoutine(actionCreators.login, sendLoginData);
    yield takeLatest(actionCreators.login.SUCCESS, loginSuccess);
    yield takeLatest(actionCreators.login.FAILURE, loginFailure);
}

export function* logoutSaga() {
    yield takeLatest(actionCreators.logout, logout);
}

function* sendLoginData(action) {    
    const { email, password } = action.payload;
    return yield call(postDataAnonymous, 'api/Auth/Login', { email: email, password: password });
}

function* loginSuccess(action) {
    //Save the token returned by the server
    window.sessionStorage.setItem("JWT", action.payload.data.token);
    window.sessionStorage.setItem("JWTExpiration", action.payload.data.expiration);

    //Set isAuthenticated = true in the store
    yield put(actionCreators.authenticated(true));

    //Redirect to the requested page
    const redirectUrl = action.payload.returnUrl;
    var returnUrl = redirectUrl ? `${redirectUrl}` : '/';
    yield put(push(returnUrl));    
}

function* loginFailure(action) {
    console.log("loginFailure Saga: ", action);
    const error = action.payload;
    if (error.errors) {
        yield put(stopSubmit("loginFormIdent", { email: error.errors.Email, password: error.errors.Password }));
        return;
    } else if (error.detail) {        
        yield put(stopSubmit("loginFormIdent", { _error: error.detail }));
        return;
    }
    yield put(stopSubmit("loginFormIdent", { _error: error }));
}


// The parameter is the action, which we destructure to get email and password 
//function* login({ email, password, redirectUrl }) {    
//    try {
//        const response = yield call(postData, 'api/Auth/Login', { email: email, password: password });
//        console.log("Got response:", response);

//        if (response.status === 200) {
//            const tokenAndExpiry = yield response.json();
//            //Allow login to survive a page reload (EG, because I edited a file)
//            window.sessionStorage.setItem("JWT", tokenAndExpiry.token);
//            window.sessionStorage.setItem("JWTExpiration", tokenAndExpiry.expiration);
//            yield put(actionCreators.loginSuccess(email));
//            var returnUrl = redirectUrl ? `${redirectUrl}` : '/';
//            yield put(push(returnUrl));
            
//            return;
//        }
        
//        if (response.status >= 400 && response.status < 500) {
//            const error = yield response.json(); //Exception if the response is not json
//            if (error.errors) {
//                yield put(stopSubmit("loginFormIdent", { email: error.errors.Email, password: error.errors.Password }));
//                return;
//            } else if (error.detail) {
//                yield put(stopSubmit("loginFormIdent", { _error: error.detail }));
//                return;
//            } 
//        }

//        yield put(stopSubmit("loginFormIdent", { _error: "The server returned an error" }));            
//    } catch (ex) {
//        console.log("Network error during fetch or exception decoding json: ", ex);
//        yield put(stopSubmit("loginFormIdent", { _error: "The server returned an error" }));
//    }
//}

function* logout() {    
    //Forget the JWT token on the client
    window.sessionStorage.removeItem('JWT');
    window.sessionStorage.removeItem('JWTExpiation');
    //By default access tokens aren't expired on the server - the SPA can simply forget about them to log out.
    //https://stackoverflow.com/questions/45748732/could-we-destroy-invalidate-jwt-token-in-asp-net-core
    //Other strategies would be: 
    //1. Make the JWT really short lived (5 minutes) and use refresh tokens. A refresh token can be used in conjenction with an expired JWT access token to generate 
    //   a new access token. Refresh tokens live in the database, BUT they are useless without the corresponding expired access token. i.e. It's not the same as
    //   keeping a plain text password in the database. The refresh token can have a fixed expiration (eg Midnignt on the 1st of Jan), so even though you get
    //   a new refresh token on each request, the hard expiry date remains unchanged. You'd normally give it 24 hours to live on first creation.
    //   This is poorly documented in Core2.2, but looks like it will be much easier (out of the box) in core 3.0.
    //2. Keep an in-memory cache of issued authentication tokens (expiring items in the cache that are older than 30 minutes on each request). Add a
    //   logout endpoint that removes the corresponding token from the cache.
    yield put(push('/login'));    
}