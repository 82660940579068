import * as React from 'react';
import { Loading } from './utils/Loading'
import { MarketAnalysis } from './MarketAnalysis';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const MarketAnalysisModal = ({ isOpen, toggle, marketAnalysis }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Detailed Analysis</ModalHeader>
            <ModalBody>
                <Loading component={MarketAnalysis}
                        loading={marketAnalysis.loading}
                        dataError={marketAnalysis.dataError}
                        marketAnalysis={marketAnalysis.data} />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    );
}

