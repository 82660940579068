import * as React from 'react';

export const MarketAnalysis = (props) => {
    return (
        <div> 
            {props.marketAnalysis}
        </div>
    );
}

